<template>
  <md-dialog :md-active="isActive">
    <md-dialog-title>Benutzer zum Projekt hinzufügen</md-dialog-title>

    <md-dialog-content>
      <md-progress-bar md-mode="indeterminate" v-if="loading" />

      <!-- Empty state -->
      <iq-empty-state
        icon="manage_accounts"
        title="Bereits alle Teammitglieder hinzugefügt!"
        v-if="!loading && allUsers.length === 0"
      />

      <!-- User select -->
<!--      <md-field v-if="!loading && allUsers.length > 0">-->
<!--        <label>Benutzer auswählen</label>-->
<!--        <md-select v-model="userId">-->
<!--          <md-option :value="user.id" v-for="user in allUsers" :key="user.id"-->
<!--            >{{ user.first_name }} {{ user.last_name }}</md-option-->
<!--          >-->
<!--        </md-select>-->
<!--      </md-field>-->

      <md-autocomplete :value="value" @md-selected="handleSelection" :md-options="users" v-if="!loading && allUsers.length > 0">
        <label>Benutzer auswählen</label>

        <template slot="md-autocomplete-item" slot-scope="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
      </md-autocomplete>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="emitClose">Abbrechen</md-button>
      <md-button class="md-primary" @click="addUser">Hinzufügen</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import resetMixin from "@/mixins/resetMixin";
import { getCompanyUsers } from "@/services/api/company";
import { addUserToProject, getProjectUsers } from "@/services/api/project";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: "userManagementDialog",
  mixins: [resetMixin, loadingMixin],
  props: {
    projectId: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      allUsers: [],
      flattenedUsers: [],
      projectUsers: [],
      userId: "",
      selectedCountry: null,
      countries: [
        'Algeria',
        'Argentina',
        'Brazil',
        'Canada',
        'Italy',
        'Japan',
        'United Kingdom',
        'United States'
      ],
      users: [],
      value: null,
    };
  },
  computed: {
    isDeleteDisabled() {
      return this.projectUsers.length === 1;
    },
  },
  async created() {
    await this.getUsers();
  },
  methods: {
    handleSelection(user) {
      if (!user?.id) {
        return;
      }
      this.userId = user.id;
    },
    async getUsers() {
      try {
        this.projectUsers = await getProjectUsers(this.projectId);
        this.allUsers = await this.getDifference(await getCompanyUsers());
        this.users = await this.getDifference(await getCompanyUsers());
        this.toggleLoading();
      } catch (error) {
        console.error(error);
        this.emitClose();
      }
    },
    async addUser() {
      try {
        console.log(this.userId)
        let response = await addUserToProject(this.projectId, this.userId);
        this.emitUserAdd(response);
      } catch (error) {
        console.error(error);
      }
    },
    getDifference(users) {
      return users.filter(
        ({ id: id1 }) => !this.projectUsers.some(({ id: id2 }) => id2 === id1)
      );
    },
    emitClose() {
      this.$emit("close");
      this.reset();
    },
    emitUserAdd(data) {
      this.$emit("add-user", data);
      this.reset();
    },
    emitError() {
      this.$emit("on-error");
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.md-dialog-content {
  width: 500px;
}
</style>
