<template>
  <iq-main>
    <!-- Loader -->
    <iq-loader :loading="loading" />

    <!-- Content -->
    <div class="container" v-if="!loading">
      <!-- Toolbar -->
      <iq-toolbar>
        <template v-slot:left>
          <!-- Back button -->
          <md-button
            class="md-primary md-icon-button back-button"
            @click="redirect({ name: 'Project', params: { id: projectId } })"
          >
            <md-icon>arrow_back</md-icon>
            <md-tooltip md-direction="right"
              >Zurück zur Projektübersicht</md-tooltip
            >
          </md-button>

          <!-- Title -->
          <div class="md-title">Benutzerverwaltung</div>
        </template>

        <template v-slot:right>
          <md-button
            class="md-primary md-icon-button margin-0"
            @click="toggleDialog('userManagement')"
          >
            <md-icon>person_add</md-icon>
            <md-tooltip md-direction="left"
              >Neuen Benutzer hinzufügen</md-tooltip
            >
          </md-button>
        </template>
      </iq-toolbar>

      <!-- Content -->
      <div class="wrapper">
        <div class="wrapper--right width-100">
          <project-user-list
            :users="projectUsers"
            @success-remove="handleRemoveUser"
            @failed-remove="
              toggleError('Benutzer konnte nicht entfernt werden')
            "
          />
        </div>
      </div>

      <!-- Dialog -->
      <user-management-dialog
        :key="dialogs.userManagementKey"
        :project-id="projectId"
        :is-active="dialogs.userManagement"
        @add-user="handleAddUser"
        @close="handleAddUserClose"
        @on-error="toggleError('Benutzer konnte nicht entfernt werden')"
      />
    </div>

    <!-- Snackbars -->
    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--error"
      :md-active.sync="isError"
    >
      <span>{{ errorMessage }}</span>
    </md-snackbar>

    <md-snackbar
      class="snackbar-adjuster snackbar-adjuster--success"
      :md-active.sync="isSuccess"
    >
      <span>{{ successMessage }}</span>
    </md-snackbar>
  </iq-main>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import ProjectUserList from "@/components/project/projectUserList";
import { getProjectUsers } from "@/services/api/project";
import errorMixin from "@/mixins/errorMixin";
import successMixin from "@/mixins/successMixin";
import UserManagementDialog from "@/components/project/userManagementDialog";
import routingMixin from "@/mixins/routingMixin";

export default {
  name: "ProjectUserManagement",
  components: { UserManagementDialog, ProjectUserList },
  mixins: [loadingMixin, errorMixin, successMixin, routingMixin],
  data() {
    return {
      projectId: "",
      projectUsers: [],
      dialogs: {
        userManagement: false,
        userManagementKey: 1,
        projectEdit: false,
      },
    };
  },
  created() {
    this.projectId = this.$route.params.id;
    this.getUsers();
  },
  methods: {
    async getUsers() {
      try {
        this.projectUsers = await getProjectUsers(this.projectId);
        this.toggleLoading();
      } catch (error) {
        console.error(error);
      }
    },
    handleRemoveUser(data) {
      this.projectUsers = data;
      this.toggleSuccess("Benutzer wurde erfolgreich entfernt!");
      this.dialogs.userManagementKey++;
    },
    handleAddUser(data) {
      this.projectUsers = data;
      this.toggleSuccess("Benutzer wurde erfolgreich zum Projekt hinzugefügt");
      this.toggleDialog("userManagement");
      this.dialogs.userManagementKey++;
    },
    handleAddUserClose() {
      this.toggleDialog("userManagement");
      this.dialogs.userManagementKey++;
    },
    toggleDialog(id) {
      this.dialogs[id] = !this.dialogs[id];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";
</style>
