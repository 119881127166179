<template>
  <div>
    <div class="item" v-for="user in users" :key="user.id">
      <!-- Avatar -->
      <div class="item--avatar">
        <md-avatar class="md-avatar-icon">
          <md-icon class="md-primary">person</md-icon>
        </md-avatar>
      </div>

      <!-- Info -->
      <div class="item--info">
        <div class="md-title">{{ user.first_name }} {{ user.last_name }}</div>
        <div class="md-caption">{{ user.email }}</div>
      </div>

      <!-- Action -->
      <div class="item--action">
        <md-button
          class="md-accent md-icon-button margin-0"
          :disabled="isDeleteDisabled"
          @click="removeUser(user.id)"
        >
          <md-icon>delete</md-icon>
          <md-tooltip md-direction="left">Benutzer löschen</md-tooltip>
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { removeUserFromProject } from "@/services/api/project";

export default {
  name: "projectUserList",
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      projectId: this.$route.params.id,
    };
  },
  computed: {
    isDeleteDisabled() {
      return this.users.length === 1;
    },
  },
  methods: {
    async removeUser(user_id) {
      try {
        let response = await removeUserFromProject(this.projectId, user_id);
        this.emitRemove(response);
      } catch (error) {
        console.error(error);
        this.failedRemove();
      }
    },
    failedRemove() {
      this.$emit("failed-remove");
    },
    emitRemove(data) {
      this.$emit("success-remove", data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main";
@import "../../assets/css/mixins";

.item {
  margin-top: $margin;
  padding: $padding;
  border: $border-default;
  border-radius: $border-radius;
  background-color: white;
  display: flex;
  align-items: center;

  &:first-child {
    margin-top: initial;
  }

  &--action {
    margin-left: auto;
  }

  @include borderHover;
}
</style>
